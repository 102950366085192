import React, { createContext, useState, useEffect } from "react";

export const ScreensizeContext = createContext({
  screenSize: "",
  setScreenSize: () => {},
});

function ScreenSizeContextWrapper({ children }) {
  const [screenSize, setScreenSize] = useState("small");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1850) {
        setScreenSize("monitor");
      } else if (window.innerWidth >= 1050) {
        setScreenSize("desktop");
      } else if (window.innerWidth > 600) {
        setScreenSize("tablet");
      } else {
        setScreenSize("mobile");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ScreensizeContext.Provider value={{ screenSize, setScreenSize }}>
      {children}
    </ScreensizeContext.Provider>
  );
}

export default ScreenSizeContextWrapper;
